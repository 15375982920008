import React from 'react';
import { FaLinkedin } from 'react-icons/fa'; // Importar el ícono de LinkedIn
import './AboutUs.css';

// Importar las imágenes correctamente
import Rola from '../assets/Rola.jpg';
import Cami from '../assets/Cami.jpg';
import Joshe from '../assets/Joshe.jpg';
import Foca from '../assets/Foca.jpg';
import Jorge from '../assets/Jorge.jpg';
import Pipe from '../assets/Pipe.jpg';
import logo from '../assets/logo_sevenpulse.png';

const AboutUs = () => {
  const teamMembers = [
    {
      name: 'Rolando Rojas',
      role: 'Product Owner',
      image: Rola,
      linkedin: 'https://www.linkedin.com/in/rerojas/'
    },
    {
      name: 'Camila Mena',
      role: 'Community Manager',
      image: Cami,
      linkedin: 'https://www.linkedin.com/in/camila-mena/'
    },
    {
      name: 'Jose Escobar',
      role: 'Scrum Master',
      image: Joshe,
      linkedin: 'https://www.linkedin.com/in/jescobarg/'
    },
    {
      name: 'Vicente Cuevas',
      role: 'Tech Lead',
      image: Foca,
      linkedin: 'https://www.linkedin.com/in/cuevas-vicente/'
    },
    {
      name: 'Jorge Cáceres',
      role: 'Testing',
      image: Jorge,
      linkedin: 'https://www.linkedin.com/in/jorge-caceresr/'
    },
    {
      name: 'Felipe Fuentes',
      role: 'User Experience',
      image: Pipe,
      linkedin: 'https://www.linkedin.com/in/felipe-fuentes-villegas/'
    },
  ];

  return (
    <div className="about-us-container">
      <h1>¿Quiénes Somos?</h1>

      <div className="logo-text-container">
        <img src={logo} alt="SevenPulse Logo" className="about-logo" />
        <p className="auto-text">
          SevenPulse es una pre-empresa destinada al desarrollo de software, con un gran objetivo de mejorar la accesibilidad y apoyar la inclusión.
        </p>
      </div>


      <div className="mission-vision-container">
        <div className="mission">
          <h2>Misión</h2>
          <p>
          Nuestra misión es romper las barreras de comunicación entre personas sordas y oyentes, proporcionando
          una solución innovadora que utiliza inteligencia artificial y visión por computadora para traducir la lengua de señas
          chilena a español en tiempo real. Nos dedicamos a mejorar la accesibilidad y la inclusión, facilitando una
          comunicación más fluida y efectiva en diversos contextos.
          </p>
        </div>

        <div className="vision">
          <h2>Visión</h2>
          <p>
          Ser líderes en inclusión y accesibilidad para la comunidad sorda en Chile, eliminando barreras de
          comunicación en el ámbito médico y asegurando que todas las personas sordas puedan acceder a una atención
          médica de calidad y privacidad, sin depender de intérpretes.
          </p>
        </div>
      </div>

      {/* Texto para "Nuestro equipo" */}
      <h2 className="team-title">Nuestro Equipo</h2>

      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.image} alt={member.name} />
            <h3>{member.name}</h3>
            <p>{member.role}</p>
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-icon">
              <FaLinkedin size={30} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
