import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import logo from '../assets/image.png';
import { FaInstagram, FaLinkedin} from 'react-icons/fa';
import { RxDoubleArrowLeft } from "react-icons/rx";


  function NavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const [isNarrowScreen, setIsNarrowScreen] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        const narrowScreen = window.innerWidth <= 768;
        setIsNarrowScreen(narrowScreen);
        if (!narrowScreen) {
          setIsOpen(false); 
        }
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const toggleMenu = () => {
      if (isNarrowScreen) {
        setIsOpen(!isOpen);
      }
    };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        {/* Icono de menú colocado a la izquierda del logo */}
        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        
        <Link to="/">
          <img src={logo} alt="DialogApp Logo" className="logo" />
        </Link>
      </div>

      {/* Enlaces de navegación */}
      <div className={`nav-links ${isOpen ? 'open' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Inicio</Link>
        <Link to="/about-us" onClick={toggleMenu}>¿Quiénes Somos?</Link>
        <Link to="/contact" onClick={toggleMenu}>Contacto</Link>

        {/* Botón de cierre del menú */}
        {isOpen && (
          <div className="close-menu-icon" onClick={toggleMenu}>
            <img src={logo} alt="DialogApp Logo" className="logo" />
            <RxDoubleArrowLeft size={24} />
          </div>
        )}

      </div>

      {/* Iconos sociales */}
      <div className="navbar-right">
        <a href="https://www.linkedin.com/company/dialogappcl/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="social-icon" />
        </a>
        <a href="https://www.instagram.com/dialogappcl/" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="social-icon" />
        </a>
      </div>
    </nav>
  );
}

  export default NavBar;
