import React from 'react';
import './Footer.css'; // Asegúrate de importar el archivo CSS
import logo1 from '../assets/logo-usm.png'; // Importa la imagen
import logo2 from '../assets/logo-fesw-300x161.png'; // Importa la imagen
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section">
                <h3>Acerca de DialogApp</h3>
                <p>Aplicación de traducción bidireccional de Lengua de señas chilena y Español.</p>
                <p>"Tu puente hacia la comunicación inclusiva"</p>
                <div className="social-icons">
                <a href="https://www.linkedin.com/company/dialogappcl/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="social-icon" />
        </a>
        <a href="https://www.instagram.com/dialogappcl/" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="social-icon" />
        </a>
                </div>
            </div>

            <div className="footer-section">
                <h3>Enlaces</h3>
                <Link to="/about-us">¿Quiénes Somos?</Link>
                <Link to="/contact">Contacto</Link>
            </div>

            <div className="footer-section">
                <h3>Contáctanos</h3>
                <p>+56 9 4113 5110</p>
                <p>Av. España, Valparaíso</p>
                <a href="mailto:sevenpulsechile@gmail.com">sevenpulsechile@gmail.com</a>
            </div>

            <div className="footer-section footer-right">
                <h3>Somos parte de</h3>
                <div className="logo-container">
                    <a href="https://www.usm.cl/">
                    <img src={logo1} alt="Logo usm" className="footer-logo" />
                    </a>
                    <a href="https://www.feriadesoftware.cl/">
                    <img src={logo2} alt="Logo fesw" className="footer-logo" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
