import React from 'react';
import './Body.css'; // Asegúrate de importar el archivo CSS
import logo from '../assets/logo dialogapp.png'; // Asegúrate de importar el logo
import pose_agachado from '../assets/pose-agachado.png';
import pose_parado from '../assets/pose-parado.png';
import pose_mano from '../assets/pose-mano.png';

const Body = () => {
    return (
        <div className="body-container">
            {/* Parte superior */}
            <div className="intro-section">
                <h1 className="intro-text">Aplicación móvil de traducción bidireccional en tiempo real entre lengua de señas Chilena y español.</h1>
                <div className="intro-image-top-container">
                    <img src={logo} alt="Logo" className="intro-image-top" />
                </div>
                <div className="intro-image-container-pose">
                    <img src={pose_parado} alt="Pose Parado" className="intro-image-pose" />
                </div>
            </div>

            {/* Parte media */}
            <div className="content-section-media">
                <div className="text-left">
                    <div className="dialogapp-section">
                        <h2 className="section-title">¿Qué es DialogApp?</h2>
                        <p>DialogApp es una innovadora aplicación móvil dirigida a la población sorda en Chile y al personal médico, que busca eliminar la dependencia de intérpretes en citas médicas, garantizando privacidad e inclusión. Utilizando inteligencia artificial y visión por computadora, ofrece traducción en tiempo real entre Lengua de Señas Chilena (LSCh) y español, promoviendo la autonomía en la comunicación. Su enfoque bidireccional y especializado en el ámbito médico la hace única, facilitando una comunicación eficiente y fluida para personas sordas en el sector salud. </p>
                    </div>
                </div>
                
                <div className="image-container">
                    <img src={pose_agachado} alt="Pose Agachado" className="pose-image" />
                </div>
            </div>

            {/* Video */}
            <div className="video-wrapper">
                <p className="video-title">Mira nuestro video para aprender más sobre DialogApp.</p>
                <div className="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/TigjN-ZZWqk?si=9Z5XnoHdg5fZkezs" title="YouTube video player" allowFullScreen></iframe>
                </div>
            </div>


            {/* Parte inferior */}
            <div className="content-section">
                <div className="text-left">
                    <div className="mission-value-section">
                        <h2>Misión y valor</h2>
                        <p>Nuestra misión es romper las barreras de comunicación entre personas sordas y oyentes, proporcionando una solución innovadora que utiliza inteligencia artificial y visión por computadora para traducir la lengua de señas chilena a español en tiempo real. Nos dedicamos a mejorar la accesibilidad y la inclusión, facilitando una comunicación más fluida y efectiva en diversos contextos.
                        Los valores que promueve DialogApp son la inclusión, la innovación, el impacto social, y la privacidad de nuestros usuarios.</p>
                    </div>
                </div>

                <div className="image-container-pose">
                    <img src={pose_mano} alt="Pose Mano" className="pose-image" />
                </div>
            </div>
        </div>
    );
};

export default Body;
