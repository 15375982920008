import React from 'react';
import './Contact.css';
import { FaPhone } from 'react-icons/fa';

const ContactUs = () => {
  
  return (
    <div className="contact-us-container">
      <h1 className="contact-title">
        ¡Síguenos en nuestras redes sociales! <FaPhone />
      </h1>
      <div className="content-container">
        <div className="social-post instagram">
          <iframe
            src="https://www.instagram.com/p/C_sso37Rpgz/embed"
            frameBorder="0"
            scrolling="no"
            allow="encrypted-media"
            title="Instagram Post"
          ></iframe>
        </div>
        <div className="social-post linkedin">
          <iframe 
            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7237840444255043584" 
            frameBorder="0" 
            allowFullScreen="" 
            title="Publicación integrada"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
